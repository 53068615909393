import { v4 as uuid_v4 } from "uuid";
import { useState } from "#imports";

type FeedbackType = "error" | "success" | "info" | "greeting";
type FeedbackSizeType = "small" | "large";

export interface IFeedback {
  message: string;
  type: FeedbackType;
  size: FeedbackSizeType;
  uuid?: string;
  title?: string;
}

const TitleMap = {
  error: "Nope",
  success: "Succès",
  info: "Hey",
  greeting: "Félicitations",
};

export const useFeedback = () => {
  const feedbacks = useState<IFeedback[]>("feedback_alive", () => []);
  const timeouts: Map<string, any> = new Map<string, any>();

  function push(feedback: IFeedback) {
    const uuid = uuid_v4();
    feedbacks.value.push({
      ...feedback,
      uuid,
      title: TitleMap[feedback.type],
    });

    timeouts.set(
      uuid,
      setTimeout(() => {
        feedbacks.value.shift();
        timeouts.delete(uuid);
      }, 3500),
    );
  }

  function remove(uuid: string) {
    const i: number = feedbacks.value.findIndex((item) => item.uuid === uuid);

    if (timeouts.has(uuid)) {
      clearTimeout(timeouts.get(uuid));
      timeouts.delete(uuid);
    }

    if (i !== -1) feedbacks.value.splice(i, 1);
  }

  function error(message: string, size: FeedbackSizeType) {
    push({
      message,
      type: "error",
      size,
    });
  }

  function success(message: string, size: FeedbackSizeType) {
    push({
      message,
      type: "success",
      size,
    });
  }

  function greeting(message: string, size: FeedbackSizeType) {
    push({
      message,
      type: "greeting",
      size,
    });
  }

  function info(message: string, size: FeedbackSizeType) {
    push({
      message,
      type: "info",
      size,
    });
  }

  return { feedbacks, remove, success, error, info, greeting };
};
